<template>
	<div>
		<div class="dialog">
			<el-form ref="ruleForm" label-width="120px">
				<el-form-item label="工单名称" prop="name">
					<span class="xx"></span>
					<el-input type="text" clearable v-model="accountability.name" placeholder=""></el-input>
				</el-form-item>
				<el-form-item label="主办方" prop="name">
					<span class="xx"></span>
					<el-input type="text" clearable v-model="accountability.gridMember.name" placeholder=""></el-input>
				</el-form-item>
				<el-form-item label="反馈" prop="name">
					<span class="xx"></span>
					<el-input type="textarea" clearable v-model="text" placeholder=""></el-input>
				</el-form-item>
				<div class="dialog-title">文件</div>
				<el-form-item label="图片">
					<div class="dialog-up">
						<div class="dialog-img-box" v-for="(item,index) in fulist(accountability.typeindex)"
							:key="index">
							<el-image class="dialog-img" :src="item"
								:preview-src-list="fulist(accountability.typeindex)" :initial-index="index" fit="fill">
							</el-image>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="附件">
					<div class="dialog-up dialog-upf">
						<!-- 文件 -->
						<el-button
							v-if="accountability.typeindex==2?accountability.accountability_appendix:accountability.supervision[0].supervision_appendix"
							@click="download(accountability.typeindex==2?accountability.accountability_appendix:accountability.supervision[0].supervision_appendix)"
							size="mini">
							{{accountability.typeindex==2?accountability.accountability_appendix:accountability.supervision[0].supervision_appendix}}
							下载
						</el-button>
					</div>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'

	export default {
		props: ['title', 'accountability'],
		data() {
			return {
				text: ''
			}
		},
		mounted() {
			if (this.accountability.typeindex == 2) {
				this.text = this.accountability.accountability_content
			} else {
				this.text = this.accountability.supervision[0].supervision_content
			}
		},
		methods: {
			// 图片数组
			fulist(type) {
				if (type == 2) {
					if (this.accountability.accountability_list) {
						return this.accountability.accountability_list.split(",")
					}
				} else {
					if (this.accountability.supervision[0].supervision_list) {
						return this.accountability.supervision[0].supervision_list.split(",")
					}
				}
			},
			// 下载图片
			download(url) {
				window.location.href = url
			}
		}
	}
</script>

<style scoped lang="scss">
	.dialog-up {
		button {
			margin-bottom: 10px;
		}

		.top {
			font-size: 12px;
			position: absolute;
			top: 0;
			height: 20px;
			line-height: 20px;
			right: 0;

			i {
				padding: 3px;
				cursor: pointer;
				font-size: 14px;
				margin-left: 5px;
				color: #FFFFFF;
				background: rgba(0, 0, 0, 0.5);
			}
		}
	}

	.dialog-title {
		margin: 40px 0;
		font-size: 16px;
	}

	.dialog-up {
		margin-top: 7px;
	}

	.dialog-upf {
		margin-top: 7px;
	}

	.seeupload {
		margin-right: 20px;
	}

	.seeupload span {
		cursor: pointer;
		font-size: 12px;
	}

	.seeupload span:hover {
		color: #5473E8;
	}

	.dialog-img-box {
		position: relative;
		height: 80px;
		overflow: hidden;
		width: 80px;
		margin-right: 15px;
		border: 1px solid #dcdde0;
		border-radius: 4px;
		text-align: center;
	}

	.dialog-img-box p {
		font-size: 12px;
		cursor: pointer;
		width: 80px;
		position: absolute;
		bottom: 0;
		height: 20px;
		line-height: 20px;
		left: 0;
		text-align: center;
		background: rgba(0, 0, 0, 0.5);
		color: #FFFFFF;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		line-clamp: 1;
		-webkit-box-orient: vertical;
	}
</style>
