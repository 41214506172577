<template>
	<div>
		<div class="dialog">
			<el-form ref="ruleForm" label-width="100px">
				<el-form-item label="排序">
					<span class="xx"></span>
					<el-input clearable v-model="ruleForm.sort" placeholder="请输入排序"></el-input>
				</el-form-item>
				<el-form-item label="工单编号">
					<span class="xx">*</span>
					<el-input clearable v-model="ruleForm.order_number" placeholder="请输入工单编号"></el-input>
				</el-form-item>
				<el-form-item label="工单标题">
					<span class="xx">*</span>
					<el-input clearable v-model="ruleForm.name" placeholder="请输入工单标题"></el-input>
				</el-form-item>
				<el-form-item label="交办时间">
					<span class="xx">*</span>
					<el-date-picker @change="startTime" :disabled="title=='编辑'&&!status?true:false" clearable v-model="ruleForm.start_time"
						type="datetime" placeholder="选择交办时间">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="应办结时间">
					<span class="xx">*</span>
					<el-date-picker :disabled="title=='编辑'&&!status?true:false" clearable
						v-model="ruleForm.completion_time" type="datetime" placeholder="选择应办结时间">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="诉求主体">
					<span class="xx">*</span>
					<el-select clearable v-model="ruleForm.subject" placeholder="请选择诉求主体">
						<el-option label="企业" :value="1"></el-option>
						<el-option label="个人" :value="0"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="特殊情形">
					<span class="xx">*</span>
					<el-select clearable v-model="ruleForm.special" placeholder="请选择特殊情形">
						<el-option label="一般工单" :value="0"></el-option>
						<el-option label="重点工单" :value="1"></el-option>
						<el-option label="紧急工单" :value="2"></el-option>
						<el-option label="预警工单" :value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="诉求类型">
					<span class="xx">*</span>
					<el-select clearable filterable v-model="ruleForm.appeal_cate_id" placeholder="请选择诉求类型(支持名称模糊搜索)">
						<el-option v-for="item in sqoptions" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="业务分类">
					<span class="xx">*</span>
					<el-select clearable filterable v-model="ruleForm.business_cate_id" placeholder="请选择业务分类(支持名称模糊搜索)">
						<el-option v-for="item in ywoptions" :key="item.id" :label="item.mergename" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="具体地址">
					<span class="xx">*</span>
					<el-input clearable v-model="ruleForm.address" placeholder="请输入具体地址"></el-input>
				</el-form-item>
				<el-form-item label="区域网络总长">
					<span class="xx">*</span>
					<el-select :multiple="title=='编辑'?true:false" filterable @change="zchange" @clear="zchange"
						:disabled="title=='编辑'&&!status?true:false" clearable v-model="zid"
						placeholder="请选择区域网络总长(支持名称模糊搜索)">
						<el-option v-for="item in qwzoptions" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
					<div class="duanxin">
						<span>是否发送短信</span>
						<el-switch active-color="#5473E8" :active-value="1" :inactive-value="0"
							v-model="ruleForm.regional_total_sms"></el-switch>
					</div>
				</el-form-item>
				<el-form-item label="区域网络格长">
					<span class="xx">*</span>
					<el-select filterable :disabled="(!zid||title=='编辑')&&!status?true:false" @change="qwgtchange"
						@clear="qwgtchange" clearable v-model="qwgtid" placeholder="请选择区域网络格长(支持名称模糊搜索)">
						<el-option v-for="item in wgzlist" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
					<div class="duanxin">
						<span>是否发送短信</span>
						<el-switch active-color="#5473E8" :active-value="1" :inactive-value="0"
							v-model="ruleForm.regional_sms"></el-switch>
					</div>
				</el-form-item>
				<el-form-item label="网格长">
					<span class="xx">*</span>
					<el-select filterable @change="wgzchange" :disabled="(!qwgtid||title=='编辑')&&!status?true:false"
						@clear="wgzchange" clearable v-model="wgzid" placeholder="请选择网格长(支持名称模糊搜索)">
						<el-option v-for="item in wgzoptions" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
					<div class="duanxin">
						<span>是否发送短信</span>
						<el-switch active-color="#5473E8" :active-value="1" :inactive-value="0"
							v-model="ruleForm.grid_chief_sms"></el-switch>
					</div>
				</el-form-item>
				<el-form-item label="网格员">
					<span class="xx">*</span>
					<el-select filterable @clear="wgychange" :disabled="(!wgzid||title=='编辑')&&!status?true:false"
						clearable v-model="ruleForm.grid_member_id" placeholder="请选择网格员(支持名称模糊搜索)">
						<el-option v-for="item in wgyoptions" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
					<div class="duanxin">
						<span>是否发送短信</span>
						<el-switch active-color="#5473E8" :active-value="1" :inactive-value="0"
							v-model="ruleForm.grid_member_sms"></el-switch>
					</div>
				</el-form-item>
				<el-form-item label="附件">
					<span class="xx"></span>
					<div class="fupload-box">
						<div>
							<el-upload class="fupload" list-type="picture-card" :on-success="handleAvatarSuccess"
								:headers="Headers" :action="ActionUrl">
								<i class="icon iconfont icon-shangchuan"></i>
							</el-upload>
						</div>
						<div class="info-list" v-if="imgList.length>0">
							<p v-for="(item,index) in imgList" :key="index">
								{{item}}
								<span class="del" @click="DelImg(index)">删除</span>
							</p>
						</div>
					</div>
				</el-form-item>
			</el-form>
		</div>
		<!-- 工单描述 -->
		<content ref="content" :title="title" :contentText="ruleForm.describes" @Submitinput="Submitinput"></content>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/work";
	import Quill from 'quill'
	import {
		lineHeightStyle
	} from '@/js/lineHeight'
	import content from './content.vue'
	export default {
		props: ['title', 'mid', 'status'],
		components: {
			content
		},
		data() {
			return {
				imgList: [],
				Headers: {
					authorization: '',
					appid: '',
					nonce: '',
					timestamp: '',
					sign: ''
				},
				ActionUrl: '',
				wgyoptions: [],
				zid: [],
				wgzid: '',
				qwgtid: '',
				wgzoptions: [],
				wgzlist: [],
				ywoptions: [],
				sqoptions: [],
				qwzoptions: [],
				ruleForm: {
					sort: 0,
					name: '',
					describes: '',
					special: '',
					subject: '',
					publisher: '',
					address: '',
					completion_time: '',
					appeal_cate_id: '',
					business_cate_id: '',
					grid_member_id: '',
					appeal_time: '',
					order_number: '',
					enclosure: '',
					regional_total_sms: 1,
					grid_chief_sms: 1,
					regional_sms: 1,
					grid_member_sms: 1,
					start_time: ''
				},
				ids: [],
			}
		},
		mounted() {
			// 上转图片url
			this.Headers.authorization = sessionStorage.getItem('token')
			let param = JSON.parse(sessionStorage.getItem('param'))
			this.Headers.appid = param.appid
			this.Headers.nonce = param.nonce
			this.Headers.timestamp = param.timestamp
			this.Headers.sign = param.sign
			this.ActionUrl = sessionStorage.getItem('baseURL') + '/System/FormUpload'
			// 发布人
			this.ruleForm.publisher = sessionStorage.getItem('realname')
			this.getFiveCateList()
			this.getList()
			this.getUserList()
			if (this.title == '编辑' || this.title == '编辑派单') {
				this.getread()
			}
		},
		methods: {
			// 交办时间
			startTime() {
				if (this.getTime()) {
					ElMessage({
						message: '交办时间不能大于现在的时间,请重新选择时间',
						type: 'warning',
					})
					this.ruleForm.start_time = ""
				}
			},
			// 获取当前时间的时间戳
			getTime() {
				let t = new Date().getTime();
				let d = Math.round(new Date(this.ruleForm.start_time) / 1000) * 1000
				console.log(t)
				console.log(d)
				if (d > t) {
					return true
				} else {
					return false
				}
			},
			// 删除附件
			DelImg(index) {
				this.imgList.splice(index, 1)
			},
			// 上转附件
			handleAvatarSuccess(res, file) {
				this.imgList.push(res.data)
			},
			// 获取详情
			getread() {
				this.ids = []
				this.zid = []
				this.$http.post(api.read, {
						order_sn: this.mid
					})
					.then((res) => {
						if (res.code == 1) {
							let ids = res.data.regional_total_id ? res.data.regional_total_id.split(",") : []
							ids.forEach(item => {
								this.ids.push(Number(item))
							})
							this.ruleForm = res.data
							this.zid = this.ids
							this.getUserLisqwgt(res.data.regional_total_id)
							this.qwgtid = res.data.regional_id ? Number(res.data.regional_id) : ''
							this.getUserLiswgz(res.data.regional_id)
							this.wgzid = res.data.grid_chief_id ? Number(res.data.grid_chief_id) : ''
							this.getUserLiswgy(res.data.grid_chief_id)
							this.ruleForm.grid_member_id = res.data.grid_member_id ? Number(res.data.grid_member_id) :
								''
							if (res.data.enclosure) {
								this.imgList = res.data.enclosure.split(",")
							}
							// 发布人
							this.ruleForm.publisher = sessionStorage.getItem('realname')
						}
					})
			},
			// 监听描述内容
			Submitinput(e) {
				this.ruleForm.describes = e
			},
			// 网格员选择
			wgychange(e) {
				this.ruleForm.grid_member_id = ''
			},
			// 网格长选择
			wgzchange(e) {
				this.ruleForm.grid_member_id = ''
				this.wgyoptions = []
				this.getUserLiswgy(e)
			},
			// 获取网格员
			getUserLiswgy(id) {
				this.$http.post(api.getUserList, {
						parent_id: id,
						check_status: 1,
					})
					.then((res) => {
						if (res.code == 1) {
							this.wgyoptions = res.data
						}
					})
			},
			// 区域网络格长选择
			qwgtchange(e) {
				this.wgzid = ''
				this.wgzoptions = []
				this.ruleForm.grid_member_id = ''
				this.wgyoptions = []
				this.getUserLiswgz(e)
			},
			// 获取网格长
			getUserLiswgz(id) {
				this.$http.post(api.getUserList, {
						parent_id: id,
						check_status: 1,
					})
					.then((res) => {
						if (res.code == 1) {
							this.wgzoptions = res.data
						}
					})
			},

			// 获取区域网络格长
			getUserLisqwgt(id) {
				this.$http.post(api.getUserList, {
						parent_id: id,
						check_status: 1,
					})
					.then((res) => {
						if (res.code == 1) {
							this.wgzlist = res.data
						}
					})
			},
			// 区域网络总长 选择
			zchange(e) {
				this.wgzid = ''
				this.qwgtid = ''
				this.ruleForm.grid_member_id = ''
				this.wgzlist = []
				this.wgzoptions = []
				this.wgyoptions = []

				this.getUserLisqwgt(e)
			},
			// 获取区域网络总长
			getUserList() {
				this.$http.post(api.getUserList, {
						parent_id: 0,
						check_status: 1,
					})
					.then((res) => {
						if (res.code == 1) {
							this.qwzoptions = res.data
						}
					})
			},
			// 诉求类型列表
			getList() {
				this.$http.post(api.getList)
					.then((res) => {
						if (res.code == 1) {
							this.sqoptions = res.data
						}
					})
			},
			// 获取5级分类
			getFiveCateList() {
				this.$http.post(api.getFiveCateList)
					.then((res) => {
						if (res.code == 1) {
							this.ywoptions = res.data
						}
					})
			},
			// 清空数据
			Reset() {
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
			},
			//新增/编辑
			SubmitForm() {
				this.ruleForm.completion_time = this.$Moment(this.ruleForm.completion_time).format('YYYY/MM/DD HH:mm:ss')
				this.ruleForm.appeal_time = this.$Moment(this.ruleForm.appeal_time).format('YYYY/MM/DD HH:mm:ss')
				this.ruleForm.enclosure = this.imgList.join(",")

				if (this.getTime()) {
					ElMessage({
						message: '交办时间不能大于现在的时间,请重新选择时间',
						type: 'warning',
					})
				} else if (this.ruleForm.order_number === '') {
					ElMessage({
						message: '请输入工单编号',
						type: 'warning',
					})
				} else if (this.ruleForm.name === '') {
					ElMessage({
						message: '工单标题',
						type: 'warning',
					})
				} else if (this.ruleForm.appeal_time === '') {
					ElMessage({
						message: '请选择诉求时间',
						type: 'warning',
					})
				} else if (this.ruleForm.completion_time === '') {
					ElMessage({
						message: '请选择应办结时间',
						type: 'warning',
					})
				} else if (this.ruleForm.subject === '') {
					ElMessage({
						message: '请选择诉求主体',
						type: 'warning',
					})
				} else if (this.ruleForm.special === '') {
					ElMessage({
						message: '请选择特殊情形',
						type: 'warning',
					})
				} else if (this.ruleForm.appeal_cate_id === '') {
					ElMessage({
						message: '请选择诉求类型',
						type: 'warning',
					})
				} else if (this.ruleForm.business_cate_id === '') {
					ElMessage({
						message: '请选择业务分类',
						type: 'warning',
					})
				} else if (this.ruleForm.address === '') {
					ElMessage({
						message: '请输入具体地址',
						type: 'warning',
					})
				} else if (this.zid === '') {
					ElMessage({
						message: '请选择区域网络总长',
						type: 'warning',
					})
				} else if (this.qwgtid === '') {
					ElMessage({
						message: '请选择区域网络格长',
						type: 'warning',
					})
				} else if (this.wgzid === '') {
					ElMessage({
						message: '请选择网格长',
						type: 'warning',
					})
				} else if (this.ruleForm.grid_member_id === '') {
					ElMessage({
						message: '请选择网格员',
						type: 'warning',
					})
				} else if (this.ruleForm.describes === '') {
					ElMessage({
						message: '请输入工单描述',
						type: 'warning',
					})
				} else {
					if (this.title == '新增') {
						this.add()
					} else if (this.status == -1) {
						this.editpai()
					} else {
						this.edit()
					}

				}
			},
			// 编辑派单
			editpai() {
				this.$http.post(api.editDispatch, this.ruleForm)
					.then((res) => {
						if (res.code == 1) {
							ElMessage({
								type: 'success',
								message: '派单成功',
							})
							this.$emit('close')
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			},
			// 编辑
			edit() {
				this.$http.post(api.edit, this.ruleForm)
					.then((res) => {
						if (res.code == 1) {
							ElMessage({
								type: 'success',
								message: '编辑成功',
							})
							this.$emit('close')
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			},
			// 新增
			add() {
				this.$http.post(api.add, this.ruleForm)
					.then((res) => {
						if (res.code == 1) {
							ElMessage({
								type: 'success',
								message: '新增成功',
							})
							this.$emit('close')
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			}
		}
	}
</script>

<style scoped lang="scss">
	.fupload-box {
		width: 100%;
		display: flex;

		.info-list {
			padding-left: 20px;
			flex: 1;
			line-height: 20px;

			p {
				font-size: 12px;
			}

			.del {
				margin-left: 20px;
				color: #999;
				cursor: pointer;
			}
		}
	}

	.duanxin {
		display: flex;
		width: 150px;
		font-size: 12px;
		text-align: right;
		justify-content: flex-end;
		align-items: center;
		height: 32px;
		margin-left: 20px;

		span {
			margin-right: 10px;
			display: inline-block;
		}
	}
</style>
