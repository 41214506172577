<template>
	<div>
		<el-dialog draggable top="5vh" :destroy-on-close="true" @close="close" v-model="drawer" title="修改延期时间">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="100px">
					<el-form-item label="选择时间">
						<span class="xx">*</span>
						<el-date-picker v-model="time" type="datetime" placeholder="选择延期时间">
						</el-date-picker>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="drawer = false">关闭</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="SubmitForm">确定</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/work";
	export default {
		data() {
			return {
				time: '',
				drawer: false,
				order_sn: ''
			}
		},
		methods: {
			// 关闭
			close() {
				this.time = ''
			},
			// 确定
			SubmitForm() {
				this.time = this.$Moment(this.time).format('YYYY/MM/DD HH:mm:ss')
				if (this.time == '') {
					ElMessage({
						message: '选择延期时间',
						type: 'warning',
					})
				} else {
					this.$http.post(api.updateTime, {
							order_sn: this.order_sn,
							completion_time: this.time
						})
						.then((res) => {
							if (res.code == 1) {
								ElMessage({
									type: 'success',
									message: '修改成功',
								})
								this.$emit('SubmitForm')
								this.drawer = false
							}
						})
				}

			},
			// 显示
			show(order_sn) {
				this.order_sn = order_sn
				this.drawer = true
			}
		}
	}
</script>

<style scoped>

</style>
