<template>
	<div>
		<el-dialog draggable width="85%" top="5vh" :destroy-on-close="true" v-model="seeouterVisible" title="查看工单">
			<div class="dialog dialogs">
				<el-form ref="ruleForm" label-width="100px">
					<el-form-item label="排序">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleForm.sort" placeholder=" "></el-input>
					</el-form-item>
					<el-form-item label="工单编号">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleForm.order_number" placeholder=" "></el-input>
					</el-form-item>
					<el-form-item label="工单标题">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleForm.name" placeholder=" "></el-input>
					</el-form-item>
					<el-form-item label="交办时间">
						<span class="xx"></span>
						<el-date-picker disabled clearable v-model="ruleForm.start_time" type="datetime"
							placeholder=" ">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="应办结时间">
						<span class="xx"></span>
						<el-date-picker disabled clearable v-model="ruleForm.completion_time" type="datetime"
							placeholder=" ">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="诉求主体">
						<span class="xx"></span>
						<el-select disabled clearable v-model="ruleForm.subject" placeholder=" ">
							<el-option label="企业" :value="1"></el-option>
							<el-option label="个人" :value="0"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="特殊情形">
						<span class="xx"></span>
						<el-select disabled clearable v-model="ruleForm.special" placeholder=" ">
							<el-option label="一般工单" :value="0"></el-option>
							<el-option label="重点工单" :value="1"></el-option>
							<el-option label="紧急工单" :value="2"></el-option>
							<el-option label="预警工单" :value="3"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="诉求类型">
						<span class="xx"></span>
						<el-select disabled clearable filterable v-model="ruleForm.appeal_cate_id"
							placeholder=" ">
							<el-option v-for="item in sqoptions" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="业务分类">
						<span class="xx"></span>
						<el-select disabled clearable filterable v-model="ruleForm.business_cate_id"
							placeholder=" ">
							<el-option v-for="item in ywoptions" :key="item.id" :label="item.mergename"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="具体地址">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleForm.address" placeholder=" "></el-input>
					</el-form-item>
					<el-form-item label="区域网络总长">
						<span class="xx"></span>
						<el-select multiple disabled filterable @change="zchange" @clear="zchange" clearable v-model="zid"
							placeholder=" ">
							<el-option v-for="item in qwzoptions" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
						<div class="duanxin">
							<span>是否发送短信</span>
							<el-switch disabled active-color="#5473E8" :active-value="1" :inactive-value="0"
								v-model="ruleForm.regional_total_sms"></el-switch>
						</div>
					</el-form-item>
					<el-form-item label="区域网络格长">
						<span class="xx"></span>
						<el-select disabled filterable :disabled="!zid?true:false" @change="qwgtchange"
							@clear="qwgtchange" clearable v-model="qwgtid" placeholder=" ">
							<el-option v-for="item in wgzlist" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
						<div class="duanxin">
							<span>是否发送短信</span>
							<el-switch disabled active-color="#5473E8" :active-value="1" :inactive-value="0"
								v-model="ruleForm.regional_sms"></el-switch>
						</div>
					</el-form-item>
					<el-form-item label="网格长">
						<span class="xx"></span>
						<el-select disabled filterable @change="wgzchange" :disabled="!qwgtid?true:false"
							@clear="wgzchange" clearable v-model="wgzid" placeholder=" ">
							<el-option v-for="item in wgzoptions" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
						<div class="duanxin">
							<span>是否发送短信</span>
							<el-switch disabled active-color="#5473E8" :active-value="1" :inactive-value="0"
								v-model="ruleForm.grid_chief_sms"></el-switch>
						</div>
					</el-form-item>
					<el-form-item label="网格员">
						<span class="xx"></span>
						<el-select disabled filterable @clear="wgychange" :disabled="!wgzid?true:false" clearable
							v-model="ruleForm.grid_member_id" placeholder=" ">
							<el-option v-for="item in wgyoptions" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
						<div class="duanxin">
							<span>是否发送短信</span>
							<el-switch disabled active-color="#5473E8" :active-value="1" :inactive-value="0"
								v-model="ruleForm.grid_member_sms"></el-switch>
						</div>
					</el-form-item>
					<el-form-item label="附件">
						<span class="xx"></span>
						<div class="fupload-box">
							
							<div class="info-list" v-if="imgList.length>0">
								<p v-for="(item,index) in imgList" :key="index">
									{{item}}
								</p>
							</div>
							<div v-else style="font-size: 12px;">
								暂无附件
							</div>
						</div>
					</el-form-item>
					<el-form-item label="工单描述">
						<span class="xx"></span>
						<div class="word-text" v-html="ruleForm.describes"></div>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="seeouterVisible = false">关闭</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/work";
	import Quill from 'quill'
	import {
		lineHeightStyle
	} from '@/js/lineHeight'
	export default {
		data() {
			return {
				order_sn: '',
				imgList: [],
				wgyoptions: [],
				zid: [],
				wgzid: '',
				qwgtid: '',
				wgzoptions: [],
				wgzlist: [],
				ywoptions: [],
				sqoptions: [],
				qwzoptions: [],
				ruleForm: {
					publisher: '',
					sort: 0,
					name: '',
					describes: '',
					special: '',
					subject: '',
					publisher: '',
					address: '',
					completion_time: '',
					appeal_cate_id: '',
					business_cate_id: '',
					grid_member_id: '',
					appeal_time: '',
					order_number: '',
					enclosure: '',
					regional_total_sms: 1,
					grid_chief_sms: 1,
					regional_sms: 1,
					grid_member_sms: 1
				},
				seeouterVisible: false,
				ids:[]
			}
		},
		mounted() {},
		methods: {
			// 显示
			show(order_sn) {
				this.getUserList()
				this.getFiveCateList()
				this.getList()
				this.order_sn = order_sn
				this.seeouterVisible = true
				this.getread()
			},
			// 获取详情
			getread() {
				this.ids = []
				this.zid = []
				this.$http.post(api.read, {
						order_sn: this.order_sn
					})
					.then((res) => {
						if (res.code == 1) {
							let ids = res.data.regional_total_id?res.data.regional_total_id.split(","):[]
							ids.forEach(item=>{
								this.ids.push(Number(item))
							})
							this.ruleForm = res.data
							this.zid = this.ids
							this.getUserLisqwgt(res.data.regional_total_id)
							this.qwgtid = res.data.regional_id?Number(res.data.regional_id):''
							this.getUserLiswgz(res.data.regional_id)
							this.wgzid = res.data.grid_chief_id?Number(res.data.grid_chief_id):''
							this.getUserLiswgy(res.data.grid_chief_id)
							this.ruleForm.grid_member_id = res.data.grid_member_id?Number(res.data.grid_member_id):''
							if (res.data.enclosure) {
								this.imgList = res.data.enclosure.split(",")
							}
						}
					})
			},
			// 获取网格员
			getUserLiswgy(id) {
				this.$http.post(api.getUserList, {
						parent_id: id,
						check_status: 1,
					})
					.then((res) => {
						if (res.code == 1) {
							this.wgyoptions = res.data
						}
					})
			},
			// 获取网格长
			getUserLiswgz(id) {
				this.$http.post(api.getUserList, {
						parent_id: id,
						check_status: 1,
					})
					.then((res) => {
						if (res.code == 1) {
							this.wgzoptions = res.data
						}
					})
			},

			// 获取区域网络格长
			getUserLisqwgt(id) {
				this.$http.post(api.getUserList, {
						parent_id: id,
						check_status: 1,
					})
					.then((res) => {
						if (res.code == 1) {
							this.wgzlist = res.data
						}
					})
			},
			// 获取区域网络总长
			getUserList() {
				this.$http.post(api.getUserList, {
						parent_id: 0,
						check_status: 1,
					})
					.then((res) => {
						if (res.code == 1) {
							this.qwzoptions = res.data
						}
					})
			},
			// 诉求类型列表
			getList() {
				this.$http.post(api.getList)
					.then((res) => {
						if (res.code == 1) {
							this.sqoptions = res.data
						}
					})
			},
			// 获取5级分类
			getFiveCateList() {
				this.$http.post(api.getFiveCateList)
					.then((res) => {
						if (res.code == 1) {
							this.ywoptions = res.data
						}
					})
			}
		}
	}
</script>

<style scoped lang="scss">
	.dialogs ::v-deep .el-input__inner {
		background-color: #fff;
		color: #333;
		cursor: not-allowed;
	}
	.word-text{
		background-color: #f9f9f9;
		padding: 15px;
		border-radius: 10px;
		width: 100%;
	}

	.fupload-box {
		width: 100%;
		display: flex;

		.info-list {
			padding-left: 20px;
			flex: 1;
			line-height: 20px;

			p {
				font-size: 12px;
			}

			.del {
				margin-left: 20px;
				color: #999;
				cursor: pointer;
			}
		}
	}

	.duanxin {
		display: flex;
		width: 150px;
		font-size: 12px;
		text-align: right;
		justify-content: flex-end;
		align-items: center;
		height: 32px;
		margin-left: 20px;

		span {
			margin-right: 10px;
			display: inline-block;
		}
	}
</style>
