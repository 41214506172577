<template>
	<div>
		<el-dialog draggable top="5vh" :destroy-on-close="true" v-model="drawer" title="重新派单">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="100px">
					<el-form-item label="区域网络总长">
						<span class="xx">*</span>
						<el-select filterable @change="zchange" @clear="zchange" clearable v-model="zid"
							placeholder="请选择区域网络总长(支持名称模糊搜索)">
							<el-option v-for="item in qwzoptions" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="区域网络格长">
						<span class="xx">*</span>
						<el-select filterable :disabled="!zid?true:false" @change="qwgtchange" @clear="qwgtchange"
							clearable v-model="qwgtid" placeholder="请选择区域网络格长(支持名称模糊搜索)">
							<el-option v-for="item in wgzlist" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="网格长">
						<span class="xx">*</span>
						<el-select filterable @change="wgzchange" :disabled="!qwgtid?true:false" @clear="wgzchange"
							clearable v-model="wgzid" placeholder="请选择网格长(支持名称模糊搜索)">
							<el-option v-for="item in wgzoptions" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="网格员">
						<span class="xx">*</span>
						<el-select filterable @clear="wgychange" :disabled="!wgzid?true:false" clearable
							v-model="grid_member_id" placeholder="请选择网格员(支持名称模糊搜索)">
							<el-option v-for="item in wgyoptions" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="drawer = false">关闭</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="SubmitForm">确定</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/work";
	export default {
		data() {
			return {
				wgyoptions: [],
				zid: '',
				wgzid: '',
				qwgtid: '',
				wgzoptions: [],
				wgzlist: [],
				qwzoptions: [],
				grid_member_id: '',
				drawer: false,
				order_sn: ''
			}
		},
		mounted() {
			this.getUserList()
		},
		methods: {
			// 确定派单
			SubmitForm() {
				this.$http.post(api.distribute, {
						order_sn: this.order_sn,
						grid_member_id: this.grid_member_id
					})
					.then((res) => {
						if (res.code == 1) {
							ElMessage({
								type: 'success',
								message: '派单成功',
							})
							this.$emit('SubmitForm')
							this.drawer = false
						}
					})
			},
			// 显示
			show(order_sn) {
				this.order_sn = order_sn
				this.drawer = true
			},
			// 网格员选择
			wgychange(e) {
				this.grid_member_id = ''
			},
			// 网格长选择
			wgzchange(e) {
				this.grid_member_id = ''
				this.wgyoptions = []
				this.getUserLiswgy(e)
			},
			// 获取网格员
			getUserLiswgy(id) {
				this.$http.post(api.getUserList, {
						parent_id: id,
						check_status: 1,
						
					})
					.then((res) => {
						if (res.code == 1) {
							this.wgyoptions = res.data
						}
					})
			},
			// 区域网络格长选择
			qwgtchange(e) {
				this.wgzid = ''
				this.wgzoptions = []
				this.grid_member_id = ''
				this.wgyoptions = []
				this.getUserLiswgz(e)
			},
			// 获取网格长
			getUserLiswgz(id) {
				this.$http.post(api.getUserList, {
						parent_id: id,
						check_status: 1,
					})
					.then((res) => {
						if (res.code == 1) {
							this.wgzoptions = res.data
						}
					})
			},

			// 获取区域网络格长
			getUserLisqwgt(id) {
				this.$http.post(api.getUserList, {
						parent_id: id,
						check_status: 1,
					})
					.then((res) => {
						if (res.code == 1) {
							this.wgzlist = res.data
						}
					})
			},
			// 区域网络总长 选择
			zchange(e) {
				this.wgzid = ''
				this.qwgtid = ''
				this.grid_member_id = ''
				this.wgzlist = []
				this.wgzoptions = []
				this.wgyoptions = []

				this.getUserLisqwgt(e)
			},
			// 获取区域网络总长
			getUserList() {
				this.$http.post(api.getUserList, {
						parent_id: 0,
						check_status: 1,
					})
					.then((res) => {
						if (res.code == 1) {
							this.qwzoptions = res.data
						}
					})
			}
		}
	}
</script>

<style scoped>

</style>
